<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <h3>Jobs</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>
        <v-stepper 
            alt-labels
            v-model="steps"
            style="box-shadow: none;"
        >
            <v-layout align-center justify-center>
                <v-row style="display: contents !important;">
                    <v-col 
                        :cols="userLoggedGetters.profile.budgetControl == 0 ? 12 : 12"
                        :lg="userLoggedGetters.profile.budgetControl == 0 ? 12 : 6"
                        :md="userLoggedGetters.profile.budgetControl == 0 ? 12 : 6"
                        :sm="userLoggedGetters.profile.budgetControl == 0 ? 12 : 12"
                    >
                        <v-stepper-header
                            style="box-shadow: none"
                        >
                            <v-stepper-step
                                :complete="steps > 1"
                                step="1"
                            >
                                Customer
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="steps > 2"
                                step="2"
                            >
                                Scheduling
                            </v-stepper-step>

                            <v-divider v-if="userLoggedGetters.profile.budgetControl == 0"></v-divider>

                            <v-stepper-step step="3" v-if="userLoggedGetters.profile.budgetControl == 0">
                                Sales Person
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-col>
                </v-row>
            </v-layout>

            <v-stepper-items>

                <!-- STEP 1: CUSTOMER  -->

                <v-stepper-content step="1">
                        
                    <v-form
                        ref="formCustomer"
                        v-model="validFormCustomer"
                        lazy-validation
                    >
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <ContentHeader :description="'Job Informations'" />
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <label>Already a customer?</label>
                                <v-radio-group 
                                    v-model="serviceRequest.isCustomer" 
                                    row
                                    @change="checkNewAddress"
                                    @click="getLists()"
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            
                            <v-col 
                                v-if="isCustomer"
                                cols="12"
                                lg="10"
                                md="8"
                                sm="12"
                            >
                                <label>Customer</label>
                                <v-combobox
                                    v-model="serviceRequest.customerSelected"
                                    :items="listCustomer" 
                                    :rules=[validations.required]
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    @change="getListCustomerAddress(true)"
                                    dense
                                ></v-combobox>
                            </v-col>

                            <v-col 
                                v-if="isCustomer == false"
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                            >
                                <label>Origin</label>
                                <v-combobox
                                    v-model="serviceRequest.customerOriginSelected"
                                    :items="listCustomerOrigin"
                                    :rules=[validations.required]
                                    item-text="description"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                            
                            <v-col 
                                v-if="isCustomer == false"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                            >
                                <label>First name</label>
                                <v-text-field
                                    v-model="serviceRequest.name" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            
                            <v-col 
                                v-if="isCustomer == false"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                            >
                                <label>Last name</label>
                                <v-text-field
                                    v-model="serviceRequest.lastName" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row
                            v-if="isCustomer"
                        >
                            <v-col 
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <label>New Address?</label>
                                <v-radio-group 
                                    v-model="newAddress" 
                                    row
                                    @change="clearAddress"
                                >
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col 
                                v-if="newAddress == 0"
                                cols="12"
                                lg="6"
                                md="4"
                                sm="12"
                            >
                                <label>Address</label>
                                <v-combobox
                                    v-model="serviceRequest.addressSelected"
                                    :items="listAddress" 
                                    :rules=[validations.required]
                                    item-text="address"
                                    item-value="id"
                                    clearable
                                    outlined
                                    @blur="getEstimatedValueZillow();checkAppointmentAddress();checkAddressProject();"
                                    dense
                                ></v-combobox>
                            </v-col>
                            <v-col 
                                v-if="newAddress == 0"
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                            >
                                <label>Main Contact</label>
                                <v-combobox
                                    v-model="serviceRequest.contactSelected"
                                    :items="listContacts" 
                                    :rules=[validations.required]
                                    item-text="description"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="newAddress == 1"
                        >
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="8"
                            >
                                <label>Address</label>
                                <v-text-field
                                    v-model="serviceRequest.address" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    @blur="getEstimatedValueZillow();checkAppointmentAddress()"
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="3"
                                md="3"
                                sm="4"
                            >
                                <label>Zip Code</label>
                                <v-text-field
                                    v-model="serviceRequest.zipCode" 
                                    :rules=[validations.required]
                                    v-mask="'#########'"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                v-if="newAddress == 1"
                                cols="12"
                                lg="3"
                                md="3"
                                sm="12"
                            >
                                <label>Contact Name</label>
                                <v-text-field
                                    v-model="serviceRequest.contactName" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="newAddress == 1"
                        >
                            <v-col  
                                cols="12"
                                lg="3"
                                md="3"
                                sm="12"
                            >
                                <label>Category</label>
                                <v-combobox
                                    v-model="serviceRequest.addressCategorySelected"
                                    :items="listAddressCategory"
                                    :rules=[validations.required]
                                    item-text="description"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                            <v-col  
                                cols="12"
                                lg="3"
                                md="3"
                                sm="4"
                            >
                                <label>State</label>
                                <v-combobox
                                    v-model="serviceRequest.stateSelected"
                                    :items="listState"
                                    :rules=[validations.required]
                                    item-text="codeDescription"
                                    item-value="id"
                                    clearable
                                    outlined
                                    @change="getListCityByState();checkAppointmentAddress()"
                                    dense
                                ></v-combobox>
                            </v-col>

                            <v-col  
                                cols="12"
                                lg="3"
                                md="3"
                                sm="8"
                            >
                                <label>City</label>
                                <v-combobox
                                    v-model="serviceRequest.citySelected"
                                    :items="listCity"
                                    :rules=[validations.required]
                                    item-text="codeStateDescriptionCity"
                                    item-value="id"
                                    clearable
                                    outlined
                                    @change="checkAppointmentAddress()"
                                    dense
                                ></v-combobox>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="3"
                                md="3"
                                sm="8"
                            >
                                <label>Subdivision</label>
                                <v-text-field
                                    v-model="serviceRequest.subdivision"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Estimated Value - Zillow</label>
                                <br />
                                <label 
                                    style="font-size: 30px !important; color: var(--color__main) !important"
                                >
                                    {{ estimatedValueZillow }}
                                </label>
                                <zillowTerms :address="address" :lgWidthTerms="'3'" :lgWidthLogo="'3'"  />
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="newAddress == 1"
                        >
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Phone</label>
                                <v-text-field
                                    v-model="serviceRequest.phone" 
                                    :rules=[validations.required]
                                    v-mask="'+1 (###) ###-####'"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Email</label>
                                <v-text-field
                                    v-model="serviceRequest.email" 
                                    :rules=[validations.required,validations.email]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="3"
                                sm="12"
                            >
                                <label>Is it a Gated Community?</label>
                                <v-radio-group 
                                    v-model="serviceRequest.isGatedCommunity"
                                    @change="clearCommunityFields()"
                                    row
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col 
                                v-if="serviceRequest.isGatedCommunity == 1"
                                cols="12"
                                lg="4"
                                md="3"
                                sm="12"
                            >
                                <label>Gated Community</label>
                                <v-text-field
                                    v-model="serviceRequest.gatedCommunity"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                v-if="serviceRequest.isGatedCommunity == 1"
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Gated Community Details</label>
                                <v-text-field
                                    v-model="serviceRequest.communityDetails"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="3"
                                sm="12"
                            >
                                <label>Dog On Site?</label>
                                <v-radio-group 
                                    v-model="serviceRequest.dogOnSite"
                                    row
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="3"
                                sm="12"
                            >
                                <label>Gate Code</label>
                                <v-text-field
                                    v-model="serviceRequest.gateCode"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
								<v-menu
									ref="mnuEstimatedDate"
									v-model="mnuEstimatedDate"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									max-width="300px"
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
                                        <label>Estimated Start Date</label>
										<v-text-field
											v-model="estimatedDateFormatted"
											prepend-inner-icon="mdi-calendar"
											v-bind="attrs"
											@blur="estimatedDate = parseDate(estimatedDateFormatted);"
                                            readonly
											outlined
											single-line
											v-on="on"
                                            dense
										></v-text-field>
									</template>
									<v-date-picker
										v-model="estimatedDate"
										no-title
										@input="mnuEstimatedDate = false"
										color="primary lighten-1"
									></v-date-picker>
								</v-menu>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Estimated Budget</label>
                                <v-text-field
                                    v-model="estimatedBudget" 
                                    v-formatMoney="moneyFormat"
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        
						<v-row>
							<v-col 
                                cols="12"
								lg="12"
								md="12"
								sm="12"
							>
								<label>Notes</label>
								<v-textarea
									v-model="serviceRequest.notes"
									single-line
									rows="4"
									row-height="30"
									outlined
								>
								</v-textarea>
							</v-col>
						</v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <ContentHeader :description="'Project Informations'" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <label>Type of work</label>
                                <v-combobox
                                    v-model="serviceRequest.serviceProject.typeWorkSelected"
                                    :items="listTypeWork" 
                                    :rules=[validations.required]
                                    item-text="kindWorkDescription"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                            
                            <v-col 
                                v-if="showOptionNewProjectOrRemodel"
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-switch
                                    v-model="serviceRequest.serviceProject.pool"
                                    label="New Pool?"
                                    color="var(--color__main)"
                                >
                                </v-switch>
                            </v-col>
                            
                            <v-col 
                                v-if="showOptionNewProjectOrRemodel"
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-switch
                                    v-model="serviceRequest.serviceProject.bbq"
                                    label="BBQ?"
                                    color="var(--color__main)"
                                >
                                </v-switch>
                            </v-col>
                            <v-col 
                                v-if="showOptionNewProjectOrRemodel"
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-switch
                                    v-model="serviceRequest.serviceProject.pergola"
                                    label="Pergola?"
                                    color="var(--color__main)"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Do you have a Survey?</label>
                                <v-radio-group 
                                    v-model="serviceRequest.serviceProject.isSurvey" 
                                    row
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <v-switch
                                    v-if="serviceRequest.id == 0"
                                    v-model="serviceRequest.serviceProject.isSameAddress"
                                    label="Same Job Address?"
                                    color="var(--color__main)"
                                    @click="checkAddressProject()"
                                >
                                </v-switch>

                                <label v-if="serviceRequest.id != 0">Address</label>
                                <v-combobox
                                    v-if="(serviceRequest.id == 0 && newAddress == 0 && serviceRequest.serviceProject.isSameAddress == true) || (serviceRequest.id != 0)"
                                    v-model="serviceRequest.serviceProject.addressSelected"
                                    :items="listAddress" 
                                    :rules=[validations.required]
                                    item-text="address"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row 
                            v-if="serviceRequest.serviceProject.isSameAddress == false || (newAddress == 1 && serviceRequest.id == 0)"
                        >
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="8"
                            >
                                <label>Address</label>
                                <v-text-field
                                    v-model="serviceRequest.serviceProject.address" 
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="4"
                            >
                                <label>Zip Code</label>
                                <v-text-field
                                    v-model="serviceRequest.serviceProject.zipCode" 
                                    :rules=[validations.required]
                                    v-mask="'#########'"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col  
                                cols="12"
                                lg="3"
                                md="3"
                                sm="12"
                            >
                                <label>Category</label>
                                <v-combobox
                                    v-model="serviceRequest.serviceProject.addressCategorySelected"
                                    :items="listAddressCategory"
                                    :rules=[validations.required]
                                    item-text="description"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                            <v-col  
                                cols="12"
                                lg="3"
                                md="3"
                                sm="4"
                            >
                                <label>State</label>
                                <v-combobox
                                    v-model="serviceRequest.serviceProject.stateSelected"
                                    :items="listState"
                                    :rules=[validations.required]
                                    item-text="codeDescription"
                                    item-value="id"
                                    clearable
                                    outlined
                                    @change="getListCityByStateProject();"
                                    dense
                                ></v-combobox>
                            </v-col>

                            <v-col  
                                cols="12"
                                lg="3"
                                md="3"
                                sm="8"
                            >
                                <label>City</label>
                                <v-combobox
                                    v-model="serviceRequest.serviceProject.citySelected"
                                    :items="listCityProject"
                                    :rules=[validations.required]
                                    item-text="codeStateDescriptionCity"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="3"
                                md="3"
                                sm="8"
                            >
                                <label>Subdivision</label>
                                <v-text-field
                                    v-model="serviceRequest.serviceProject.subdivision"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="3"
                                sm="12"
                            >
                                <label>Is it a Gated Community?</label>
                                <v-radio-group 
                                    v-model="serviceRequest.serviceProject.isGatedCommunity"
                                    @change="clearCommunityProjectFields()"
                                    row
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col 
                                v-if="serviceRequest.serviceProject.isGatedCommunity == 1"
                                cols="12"
                                lg="4"
                                md="3"
                                sm="12"
                            >
                                <label>Gated Community</label>
                                <v-text-field
                                    v-model="serviceRequest.serviceProject.gatedCommunity"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                v-if="serviceRequest.serviceProject.isGatedCommunity == 1"
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Gated Community Details</label>
                                <v-text-field
                                    v-model="serviceRequest.serviceProject.communityDetails"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="3"
                                sm="12"
                            >
                                <label>Dog On Site?</label>
                                <v-radio-group 
                                    v-model="serviceRequest.serviceProject.dogOnSite"
                                    row
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="0"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="2"
                                md="3"
                                sm="12"
                            >
                                <label>Gate Code</label>
                                <v-text-field
                                    v-model="serviceRequest.serviceProject.gateCode"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        
						<v-row>
							<v-col 
                                cols="12"
								lg="12"
								md="12"
								sm="12"
							>
								<label>Notes</label>
								<v-textarea
									v-model="serviceRequest.serviceProject.notes"
									single-line
									rows="4"
									row-height="30"
									outlined
								>
								</v-textarea>
							</v-col>
						</v-row>
                    </v-form>

                </v-stepper-content>

                <!-- STEP 2: SCHEDULING  -->

                <v-stepper-content step="2">
                    
                    <v-form
                        ref="formScheduling"
                        v-model="validFormScheduling"
                        lazy-validation
                    >
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <v-menu
                                    ref="mnuStartDate"
                                    v-model="mnuStartDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <label>Appointment Date</label>
                                        <v-text-field
                                            v-model="startDateFormatted"
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            @blur="startDate = parseDate(startDateFormatted)"
                                            readonly
                                            outlined
                                            single-line
                                            v-on="on"
                                            dense
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        no-title
                                        @input="mnuStartDate = false"
                                        color="primary lighten-1"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    
                        <v-row>
                            <v-col 
                                cols="6"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <label>Start Time</label>
                                <v-text-field
                                    v-model="timeStartDisplay"
                                    :rules=[validations.required]
                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                    v-mask="'##:## AA'"
                                    outlined
                                    single-line
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col 
                                cols="6"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <label>End Time</label>
                                <v-text-field
                                    v-model="timeEndDisplay"
                                    :rules=[validations.required]
                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                    v-mask="'##:## AA'"
                                    outlined
                                    single-line
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <v-switch 
                                    v-model="specifyAppointmentLocation"
                                    label="Specify an Appointment Location"
                                    color="var(--color__main)"
                                    @click="checkAppointmentAddress()"
                                >
                                </v-switch>
                                <v-text-field
                                    v-if="specifyAppointmentLocation"
                                    v-model="serviceRequest.appointmentAddress"
                                    :rules=[validations.required]
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row
                            v-if="specifyAppointmentLocation"
                        >
                            <v-col  
                                cols="12"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <label>State</label>
                                <v-combobox
                                    v-model="serviceRequest.appointmentStateSelected"
                                    :items="listStateAppointmentAddress"
                                    :rules=[validations.required]
                                    item-text="codeDescription"
                                    item-value="id"
                                    clearable
                                    outlined
                                    @change="getListCityByStateAppointmentAddress()"
                                    dense
                                ></v-combobox>
                            </v-col>

                            <v-col  
                                cols="12"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <label>City</label>
                                <v-combobox
                                    v-model="serviceRequest.appointmentCitySelected"
                                    :items="listCityAppointmentAddress"
                                    :rules=[validations.required]
                                    item-text="codeStateDescriptionCity"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col  
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                    <a
                                        v-on="on"
                                        v-bind:href="'https://maps.google.com/?q=' + googleMapsAddress" 
                                        target="_blank"
                                    >
                                        <v-icon color="primary" left>mdi-map-search</v-icon>
                                        {{ googleMapsAddress }}
                                    </a>
                                    </template>
                                    <span>Click here to check address in Google Maps</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-form>

                </v-stepper-content>

                <!-- STEP 3: CHOOSE A SELLER  -->
                <v-stepper-content step="3" v-if="userLoggedGetters.profile.budgetControl == 0">
                    
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-switch
                                v-model="serviceRequest.sendWelcomeEmail"
                                label="Send welcome email?"
                                color="var(--color__main)"
                            >
                            </v-switch>
                        </v-col>
                        <v-col 
                            v-if="listSellers.length > 0"
                            cols="12"
                        >
                            <label>Choose a Sales Person</label>
                            <v-simple-table
                                fixed-header
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Name
                                            </th>
                                            <th class="text-left">
                                                Jobs in the city
                                            </th>
                                            <th class="text-left">
                                                Budget
                                            </th>
                                            <th class="text-left">
                                                Appointments for this week
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            style="cursor: pointer;"
                                            v-for="(itemSeller, idx) in listSellers" :key="itemSeller.id"
                                            @click="handleClick(idx, itemSeller.id)"
                                            :class="{ selectedRow: selectedRow == idx }"
                                        >
                                            <td>{{ itemSeller.userName }}</td>
                                            <td>{{ itemSeller.worksInTheRegion }}</td>
                                            <td>{{ itemSeller.budgetFormatted }}</td>
                                            <td>{{ itemSeller.worksInTheWeek }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        
                        <v-col 
                            v-else
                            cols="12"
                            style="text-align: center;"
                        >
                            <label style="color: var(--color__red);">Unfortunately, there are no available sales people for this service. Possible reasons are:</label> <br />
                            <label style="color: var(--color__red);"> - No one with the required budget allowance.</label> <br />
                            <label style="color: var(--color__red);"> - No one with the necessary schedule availability.</label> <br /><br />
                            <label style="color: var(--color__red);">A To Do item will be created to define the correct sales person as soon as possible.</label>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
            
        <!-- Buttons: NEXT | BACK -->
        <div class="text-center">
            
            <v-row>
                <v-col 
                    cols="6"
                    :lg="steps == 3 || (steps == 2 && userLoggedGetters.profile.budgetControl == 1) ? '6' : '7'"
                    :md="steps == 3 || (steps == 2 && userLoggedGetters.profile.budgetControl == 1) ? '6' : '7'"
                    sm="8"
                >
                    <v-row>
                        <v-col 
                            style="text-align: right"
                            cols="6"
                            lg="8"
                            md="7"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        @click="cancel"
                                        style="background-color: var(--color__red) !important; color: #ffffff !important;"
                                    >
                                        Cancel
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            style="text-align: center"
                            cols="6"
                            lg="4"
                            md="5"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        :disabled="steps == 1"
                                        style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                        @click="controlStep('back')"
                                    >
                                        Back
                                    </v-btn>
                                </template>
                                <span>Back Previous Step</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="4"
                >
                    <v-row>
                        <v-col 
                            v-if="showButtonNext"
                            style="text-align: left"
                            cols="12"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        @click="controlStep('next')"
                                    >
                                        Next
                                    </v-btn>
                                </template>
                                <span>Go To Next Step</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            v-if="showButtonDone"
                            style="text-align: left"
                            cols="12"
                            lg="4"
                            md="4"
                            sm="4"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        @click="save(false)"
                                    >
                                        Done
                                    </v-btn>
                                </template>
                                <span>Save Job</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            v-if="showButtonStartImmediately"
                            style="text-align: left"
                            cols="12"
                            lg="3"
                            md="3"
                            sm="3"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        @click="saveAndStartImmediately()"
                                    >
                                        Start Immediately
                                    </v-btn>
                                </template>
                                <span>Start the Job Immediately</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import { formaterDecimalBRServer } from '@/utilities/Utils';
    import zillowTerms from '@/components/Zillow/ZillowTerms';
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TYPE_OF_WORK } from "@/utilities/Enums";
	import * as moment from 'moment';

    export default ({

        components: {
            zillowTerms,
            ContentHeader,
            ActionDialog
        },

        mixins: [Helpers],

        data: vm => ({

            validFormCustomer: true,
            validFormScheduling: true,

            steps: 1,

            estimatedValueZillow: "$ 0,00",
            estimatedBudget: "$ 0,00",

            listCustomerOrigin: [],
            address: null,
            specifyAppointmentLocation: true,

            serviceRequest: {
                id: 0,
                dateRegister: 0,
                idUserRegister: 0,
                isCustomer: 0,
                customerSelected: null,
                addressSelected: null,
                idCustomer: 0,
                idAddress: 0,
                idContact: 0,
                contactName: "",
                name: "",
                lastName: "",
                customerOriginSelected: null,
                zipCode: "",
                address: "",
                idAddressCategory: 0,
                idCity: 0,
                stateSelected: null,
                citySelected: null,
                subdivision: '',
                phone: "",
                email: "",
                estimatedDate: "",
                isGatedCommunity: 0,
                dogOnSite: 0,
                community: "",
                notes: "",
                startDate: "",
                appointmentAddress: "",
                appointmentStateSelected: null,
                appointmentCitySelected: null,
                status: "",
                idUserResponsible: 0,
                serviceProject: {
                    typeWorkSelected: {
                        id: 3,
                        kindWorkDescription: 'Service'
                    },
                    idKindWork: 3,
                    isSurvey: 1,
                    notes: "",
                    name: "",
                    isSameAddress: true,
                    pool: true,
                    bbq: false,
                    pergola: false,
                    addressSelected: null,
                    idAddress: 0,
                    zipCode: "",
                    address: "",
                    idAddressCategory: 0,
                    idCity: 0,
                    stateSelected: null,
                    citySelected: null,
                    subdivision: '',
                    isGatedCommunity: 0,
                    dogOnSite: 0,
                    community: "",
                },
                sendWelcomeEmail: 1
            },

            listTypeWork: [],

            listCustomer: [],
            listAddress: [],
            listAddressCategory: [],
            listContacts: [],
            listCity: [],
            listCityProject: [],
            listCityAppointmentAddress: [],
            listState: [],
            listStateAppointmentAddress: [],

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },
            menuTimeStart: false,
            menuTimeEnd: false,
            AMPMStart: "AM",
			timeStart: null,
			timeStartDisplay: null,
            AMPMEnd: "AM",
			timeEnd: null,
			timeEndDisplay: null,

            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            estimatedDate: vm.today,
            estimatedDateFormatted: vm.formatDate(vm.today),
            mnuEstimatedDate: false,

            startDate: vm.today,
            startDateFormatted: vm.formatDate(vm.today),
            mnuStartDate: false,

            listSellers: [],
            selectedRow: 0,
            newAddress: 1,

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            TYPE_OF_WORK,

            loading: false,

            validations: {
                required: required,
                email: validEmail,
            }
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
            
            googleMapsAddress() {
                let googleMapsAddress = this.serviceRequest.appointmentAddress;
                
                if (this.serviceRequest.appointmentStateSelected != null && this.serviceRequest.appointmentStateSelected != undefined) {
                    googleMapsAddress += `, ${this.serviceRequest.appointmentStateSelected.code}`;
                }

                if (this.serviceRequest.appointmentCitySelected != null && this.serviceRequest.appointmentCitySelected != undefined) {
                    googleMapsAddress += `, ${this.serviceRequest.appointmentCitySelected.descriptionCity ?? this.serviceRequest.appointmentCitySelected.description}`;
                }

                return googleMapsAddress;
            },

            showOptionNewProjectOrRemodel() {

                if (this.serviceRequest.serviceProject.typeWorkSelected != null && 
                    this.serviceRequest.serviceProject.typeWorkSelected != undefined) {
                    return this.serviceRequest.serviceProject.typeWorkSelected.id == this.TYPE_OF_WORK.REMODEL || 
                           this.serviceRequest.serviceProject.typeWorkSelected.id == this.TYPE_OF_WORK.NEW_PROJECT;
                }
                else {
                    return false;
                }
            },

            showButtonNext() {
                return this.steps == 1 || (this.steps == 2 && this.userLoggedGetters.profile.budgetControl == 0)
            },

            showButtonDone() {
                return this.steps == 3 || (this.steps == 2 && this.userLoggedGetters.profile.budgetControl == 1)
            },

            showButtonStartImmediately() {
                return this.showButtonNext == false && ( (this.userLoggedGetters.profile.budgetControl == 1 || this.userLoggedGetters.profile.systemAdmin == 1 ) && (this.steps == 3 || this.steps == 2) )
            },

            isCustomer() {
                return this.serviceRequest.isCustomer === 1;
            }
        },

        watch: {
            startDate () {
                this.startDateFormatted = this.formatDate(this.startDate)
            },

            closeDate () {
                this.closeDateFormatted = this.formatDate(this.closeDate)
            },

            estimatedDate () {
                this.estimatedDateFormatted = this.formatDate(this.estimatedDate)
            },
        },

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            handleClick(i, idUserResponsible) {
                this.selectedRow = i;
                this.serviceRequest.idUserResponsible = idUserResponsible
            },

            async controlStep (type) {
                if (type === 'next') {
                    switch (this.steps) {
                        case 1: 
                            
                            if (await this.validateForm(1)) {
                                this.steps = 2; 
                            }
                        break;

                        case 2: 
                        
                            if (await this.validateForm(2)) {
                                await this.listAvailableSellers();

                                if (this.userLoggedGetters.profile.budgetControl == 0) {
                                    this.steps = 3; 
                                }
                            }
                        break;

                        default: 
                            this.steps = 1; 
                        break;
                    }
                }
                else if (type === 'back') {
                    switch (this.steps) {
                        case 3: this.steps = 2; break;
                        case 2: this.steps = 1; break;
                        default: this.steps = 1; break;
                    }
                }
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async listAvailableSellers() {

                let idCity = "";

                if (this.isCustomer) {
                    if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                        idCity = this.serviceRequest.addressSelected.idCity;
                    }
                }
                else {
                    idCity = this.serviceRequest.citySelected.id;
                }

                let startDate = this.startDate;
                if (this.timeStart == null) {
                    startDate += " 00:00"
                }
                else {
                    startDate += ` ${this.timeStartDisplay}`
                }

                let endDate = this.startDate;
                if (this.timeEnd == null) {
                    endDate += " 00:00"
                }
                else {
                    endDate += ` ${this.timeEndDisplay}`
                }

                if (this.id == 0) {
                    this.serviceRequest.idUserResponsible = 0;
                }

                let avaliableSellersRequest = {
                    idService: this.id,
                    idUser: 0,
                    startDate: startDate,
                    endDate: endDate,
                    budget: formaterDecimalBRServer(this.estimatedBudget),
                    idCity: idCity
                }
                
                this.listSellers = await this.$store.dispatch("serviceModule/ListAvaliableSellers", avaliableSellersRequest);
                if (this.listSellers.length > 0) {

                    if (this.userLoggedGetters.profile.budgetControl == 1) {
                        
                        const selfSeller = this.listSellers.filter(sel => sel.id == this.userLoggedGetters.id)

                        if (selfSeller != null && selfSeller != undefined && selfSeller.length > 0) {
                        this.serviceRequest.idUserResponsible = selfSeller[0].id;
                        }
                        
                    }
                    else {
                        this.serviceRequest.idUserResponsible = this.listSellers[0].id
                    }
                }
                else {
                    avaliableSellersRequest = {
                        idUser: this.serviceRequest.idUserResponsible,
                        startDate: startDate,
                        endDate: endDate,
                        budget: formaterDecimalBRServer(this.estimatedBudget),
                        idCity: idCity
                    }

                    this.listSellers = await this.$store.dispatch("serviceModule/ListAvaliableSellers", avaliableSellersRequest);
                }
            },
            
            async clearCommunityFields() {
                if (this.serviceRequest.isGatedCommunity == 0) {
                    this.serviceRequest.gatedCommunity = "";
                    this.serviceRequest.communityDetails = "";
                }
            },
            
            async clearCommunityProjectFields() {
                if (this.serviceRequest.serviceProject.isGatedCommunity == 0) {
                    this.serviceRequest.serviceProject.gatedCommunity = "";
                    this.serviceRequest.serviceProject.communityDetails = "";
                }
            },

            async getFloridaStateDefault() {
                return {
                    id: 13,
                    codeDescription: "FL - Florida"
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = {...response.result};

                    const addressSelected = {...this.serviceRequest.addressSelected};
                    const customerSelected = {...this.serviceRequest.customerSelected};
                    const contactSelected = {...this.serviceRequest.contactSelected};

                    
                    setTimeout( async () => {

                        this.serviceRequest.customerSelected = customerSelected;

                        this.getListCustomerAddress(false);

                        this.estimatedValueZillow = this.serviceRequest.estimatedValueZillowFormatted;
                        this.estimatedBudget = this.serviceRequest.estimatedBudgetFormatted;
                        this.serviceRequest.estimatedDate = this.serviceRequest.estimatedDateFormatted;
                        this.estimatedDate = this.parseDate(this.serviceRequest.estimatedDateFormatted);
                        this.estimatedDateFormatted = this.serviceRequest.estimatedDateFormatted;
                        
                        this.serviceRequest.startDate = this.serviceRequest.startDateFormatted;
                        this.startDate = this.parseDate(this.serviceRequest.startDateFormatted);
                        this.startDateFormatted = this.serviceRequest.startDateFormatted;

                        this.timeStart = this.serviceRequest.timeStartFormatted;
                        this.AMPMStart = this.serviceRequest.ampmTimeStart;
                        this.timeStartDisplay = `${this.serviceRequest.timeStartFormatted} ${this.AMPMStart}`;
                        this.timeEnd = this.serviceRequest.timeCloseFormatted;
                        this.AMPMEnd = this.serviceRequest.ampmTimeEnd;
                        this.timeEndDisplay = `${this.serviceRequest.timeCloseFormatted} ${this.AMPMEnd}`;

                        const idUserResponsible = this.serviceRequest.idUserResponsible;

                        this.getEstimatedValueZillow();

                        let index = 0;

                        this.listSellers.forEach(itemSeller => {
                            if (itemSeller.id == idUserResponsible) {
                                this.handleClick(index, idUserResponsible)
                            }
                            index++;
                        });

                        if (this.isCustomer) {
                            if (addressSelected != null && addressSelected != undefined) {
                                this.address = addressSelected.address;
                            }
                        }
                        else {
                            this.address = this.serviceRequest.address;
                        }

                        this.specifyAppointmentLocation = this.serviceRequest.appointmentAddress != this.address || this.serviceRequest.appointmentCitySelected.id != addressSelected.idCity;
                        
                        const appointmentCitySelected = {...this.serviceRequest.appointmentCitySelected};

                        if (appointmentCitySelected) {
                            this.serviceRequest.appointmentStateSelected = appointmentCitySelected.stateSelected;
                            await this.getListCityByStateAppointmentAddress();
                            this.serviceRequest.appointmentCitySelected = appointmentCitySelected;
                        }

                        if (this.serviceRequest.serviceProject && this.serviceRequest.serviceProject != null && this.serviceRequest.serviceProject != undefined) {
                            this.serviceRequest.serviceProject.isSameAddress = true;
                        }

                        this.serviceRequest.customerSelected = customerSelected;
                        this.serviceRequest.contactSelected = contactSelected;
                    }, 200);
                }
                else {
                    this.serviceRequest.stateSelected = await this.getFloridaStateDefault();

                    const dateToday = moment().format("MM/DD/YYYY");
                    
                    const timeNowStart = moment().format("hh:mm");
                    const timeNowEnd = moment().add(1, 'hours').format("hh:mm");
                    this.AMPMStart = moment().format("A");
                    this.AMPMEnd = moment().format("A");

                    this.startDate = this.parseDate(dateToday);
                    this.startDateFormatted = dateToday;

                    this.timeStart = timeNowStart;
                    this.timeStartDisplay = `${timeNowStart} ${this.AMPMStart}`;
                    this.timeEnd = timeNowEnd;
                    this.timeEndDisplay = `${timeNowEnd} ${this.AMPMEnd}`;

                    this.specifyAppointmentLocation = false;
                    this.checkAppointmentAddress();
                    this.getListCityByState();
                }
            },

            async getListCityByState() {
                
                this.serviceRequest.citySelected = null;

                let idState = 0;

                if (this.serviceRequest.stateSelected != null && this.serviceRequest.stateSelected != undefined) {
                    idState = this.serviceRequest.stateSelected.id;
                }

                this.listCity = await this.$store.dispatch("cityModule/List", idState);
            },

            async getListCityByStateProject() {
                
                this.serviceRequest.serviceProject.citySelected = null;

                let idState = 0;

                if (this.serviceRequest.serviceProject.stateSelected != null && this.serviceRequest.serviceProject.stateSelected != undefined) {
                    idState = this.serviceRequest.serviceProject.stateSelected.id;
                }

                this.listCityProject = await this.$store.dispatch("cityModule/List", idState);
            },

            async getListCityByStateAppointmentAddress() {
                
                this.serviceRequest.appointmentCitySelected = null;

                let idState = 0;

                if (this.serviceRequest.appointmentStateSelected != null && this.serviceRequest.appointmentStateSelected != undefined) {
                    idState = this.serviceRequest.appointmentStateSelected.id;
                }

                this.listCityAppointmentAddress = await this.$store.dispatch("cityModule/List", idState);
            },

            async getLists() {
                
                const listCustomerResponse = await this.$store.dispatch("customerModule/List");
                this.listCustomer = listCustomerResponse.filter(cus => cus.status == 1);
                this.listCustomerOrigin = await this.$store.dispatch("customerOriginModule/List");
                
                this.listAddressCategory = await this.$store.dispatch("addressCategoryModule/List");
                this.listState = await this.$store.dispatch("stateModule/List");
                this.listStateAppointmentAddress = [...this.listState];
                const listTypeWork = await this.$store.dispatch("serviceModule/ListTypeOfWorks");
                this.listTypeWork = listTypeWork.filter(tow => tow.id >= 1);
            },

            async getListCustomerAddress(clearAddress) {

                if (this.serviceRequest.customerSelected != null && this.serviceRequest.customerSelected != undefined) {
                    if (clearAddress == true) {
                         this.serviceRequest.addressSelected = null;
                    }

                    const idCustomer = this.serviceRequest.customerSelected.id;
                    if (idCustomer != 0 && idCustomer != null && idCustomer != undefined) {
                        this.listAddress = await this.$store.dispatch(`customerModule/ListAddress`, idCustomer);
                        this.listContacts = await this.$store.dispatch(`customerModule/ListContacts`, idCustomer);

                        if (this.listAddress != null && this.listAddress != undefined && this.listAddress.length == 1) {
                            this.serviceRequest.addressSelected = this.listAddress[0];
                            this.getEstimatedValueZillow();
                            this.checkAppointmentAddress();
                            this.checkAddressProject();
                        }
                    }
                }
            },

            async getEstimatedValueZillow() {

                let zillowRequest = {
                    typeRequest: 2, //Address
                    zipCodeOrAddress: "",
                }

                if (this.isCustomer) {
                    if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                        zillowRequest.zipCodeOrAddress = this.serviceRequest.addressSelected.address;
                        this.address = this.serviceRequest.addressSelected.address;
                    }
                }
                else {
                    zillowRequest.zipCodeOrAddress = this.serviceRequest.address;
                    this.address = this.serviceRequest.address;
                }

                let response = await this.$vanKirkApi.post(`common/zillow`, zillowRequest, {
                        headers: {
                            "content-type": "application/json"
                        }
                    });
                let resultZillow = response.data.result;

                this.serviceRequest.zipCode = null;
                this.serviceRequest.stateSelected = await this.getFloridaStateDefault();
                this.serviceRequest.citySelected = null;

                this.estimatedValueZillow = "$ 0,00";
                    
                if (resultZillow && resultZillow.success == true && resultZillow.bundle.length > 0) {

                    this.serviceRequest.zipCode = resultZillow.bundle[0].postalCode;
                    this.estimatedValueZillow = resultZillow.bundle[0].zEstimateFormatted;

                    let state = this.listState.filter (sta => sta.code == resultZillow.bundle[0].state);
                    if (state && state != null && state != undefined && state.length > 0) {
                        this.serviceRequest.stateSelected = state[0];
                        await this.getListCityByState();
                        
                        let city = this.listCity.filter (cit => cit.descriptionCity.toString().includes(resultZillow.bundle[0].city));
                        if (city && city != null && city != undefined && city.length > 0) {
                            this.serviceRequest.citySelected = city[0];
                        }
                    }
                }
                else {
                    this.estimatedValueZillow = "Not found"
                }

                this.checkAddressProject();
            },

            async checkAppointmentAddress() {

                let stateAppointmentAddressSelected = null;
                let cityAppointmentAddressSelected = null;
                
                if (this.isCustomer) {

                    if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                        stateAppointmentAddressSelected = {
                            id: this.serviceRequest.addressSelected.idState,
                            code: this.serviceRequest.addressSelected.stateCode,
                            codeDescription: this.serviceRequest.addressSelected.stateDescription
                        }

                        cityAppointmentAddressSelected = {
                            id: this.serviceRequest.addressSelected.idCity,
                            descriptionCity: this.serviceRequest.addressSelected.cityDescription,
                            codeStateDescriptionCity: this.serviceRequest.addressSelected.codeStateDescriptionCity
                        }
                    }
                }
                else {

                    if (this.serviceRequest.citySelected != null && this.serviceRequest.citySelected != undefined) {
                        this.serviceRequest.idCity = this.serviceRequest.citySelected.id;

                        stateAppointmentAddressSelected = {...this.serviceRequest.stateSelected}

                        cityAppointmentAddressSelected = {...this.serviceRequest.citySelected}
                    }
                }

                if (stateAppointmentAddressSelected != null && cityAppointmentAddressSelected != null) {
                    this.serviceRequest.appointmentAddress = this.address;
                    this.serviceRequest.appointmentStateSelected = stateAppointmentAddressSelected;
                    await this.getListCityByStateAppointmentAddress();
                    this.serviceRequest.appointmentCitySelected = cityAppointmentAddressSelected;
                }
            },

            async validateForm(step) {

                if (step == 1) {
                    await this.$refs.formCustomer.validate();

                    if (this.validFormCustomer === false) {
                        this.showToast("error", "Warning!", "There are inconsistencies in this step. Please review!");
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (step == 2) {

                    var validDateTime = this.timeStart != null && this.timeEnd != null;

                    if (validDateTime === false) {
                        this.showToast("error", "Warning!", "Please select start and end time!");
                        return false;
                    }
                    else {

                        if (!this.timeStartDisplay.toString().includes("AM") && !this.timeStartDisplay.toString().includes("PM")) {
                            this.showToast("error", "Warning!", "Invalid start time!");
                            return false;
                        }
                        else if (!this.timeEndDisplay.toString().includes("AM") && !this.timeEndDisplay.toString().includes("PM")) {
                            this.showToast("error", "Warning!", "Invalid end time!");
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                }
                else {

                    if (this.serviceRequest.serviceProject.typeWorkSelected == 2) {
                        if ((this.serviceRequest.serviceProject.pool == 0 || this.serviceRequest.serviceProject.pool == false) &&
                            (this.serviceRequest.serviceProject.bbq == 0 || this.serviceRequest.serviceProject.bbq == false) &&
                            (this.serviceRequest.serviceProject.pergola == 0 || this.serviceRequest.serviceProject.pergola == false)) {

                            this.showToast("error", "Warning!", "You must select at least option of [Pool, BBQ or Pergola]!");
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                    else {
                        return true;
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/service/serviceList" });
            },

            async save(startImmediately) {

                if (await this.validateForm(0)) {

                    this.loading = true;
                    this.showLoading();

                    this.serviceRequest.id = this.id;
                    this.serviceRequest.estimatedDate = this.estimatedDate;
                    this.serviceRequest.startDate = this.startDate + " " + this.timeStartDisplay;
                    this.serviceRequest.closeDate = this.startDate + " " + this.timeEndDisplay;
                    if (this.estimatedValueZillow != "Not found") {
                        this.serviceRequest.estimatedValueZillow = formaterDecimalBRServer(this.estimatedValueZillow);
                    }
                    else {
                        this.serviceRequest.estimatedValueZillow = "0.00"
                    }
                    this.serviceRequest.estimatedBudget = formaterDecimalBRServer(this.estimatedBudget);

                    if (this.isCustomer) {
                        this.serviceRequest.idCustomer = this.serviceRequest.customerSelected.id;
                        this.serviceRequest.idAddress = this.serviceRequest.addressSelected.id;
                        this.serviceRequest.idContact = this.serviceRequest.contactSelected.id;
                    }

                    if (this.newAddress == 1) {
                        this.serviceRequest.idAddress = 0;

                        this.serviceRequest.idAddressCategory = this.serviceRequest.addressCategorySelected.id;
                        this.serviceRequest.idCity = this.serviceRequest.citySelected.id;
                        
                        if (this.serviceRequest.customerOriginSelected != null && this.serviceRequest.customerOriginSelected != undefined) {
                            this.serviceRequest.idCustomerOrigin = this.serviceRequest.customerOriginSelected.id;
                        }

                        this.serviceRequest.idContact = 0;
                    }

                    if (this.specifyAppointmentLocation == false) {
                        this.serviceRequest.appointmentAddress = this.address;
                    }
                    if (this.serviceRequest.appointmentCitySelected != null && this.serviceRequest.appointmentCitySelected != undefined) {
                        this.serviceRequest.appointmentIDCity = this.serviceRequest.appointmentCitySelected.id;
                    }

                    if (this.serviceRequest.serviceProject.typeWorkSelected != null && this.serviceRequest.serviceProject.typeWorkSelected != undefined) {
                        this.serviceRequest.serviceProject.idKindWork = this.serviceRequest.serviceProject.typeWorkSelected.id;
                    }

                    if (this.serviceRequest.serviceProject.pool == true) {
                        this.serviceRequest.serviceProject.pool = 1;
                    }
                    else {
                        this.serviceRequest.serviceProject.pool = 0;
                    }

                    if (this.serviceRequest.serviceProject.bbq == true) {
                        this.serviceRequest.serviceProject.bbq = 1;
                    }
                    else {
                        this.serviceRequest.serviceProject.bbq = 0;
                    }

                    if (this.serviceRequest.serviceProject.pergola == true) {
                        this.serviceRequest.serviceProject.pergola = 1;
                    }
                    else {
                        this.serviceRequest.serviceProject.pergola = 0;
                    }

                    if (this.serviceRequest.serviceProject.idKindWork != this.TYPE_OF_WORK.REMODEL &&
                        this.serviceRequest.serviceProject.idKindWork != this.TYPE_OF_WORK.NEW_PROJECT) {
                        this.serviceRequest.serviceProject.pool = 0;
                        this.serviceRequest.serviceProject.bbq = 0;
                        this.serviceRequest.serviceProject.pergola = 0;
                    }

                    if (this.serviceRequest.serviceProject.isSameAddress == true) {
                        this.serviceRequest.serviceProject.isSameAddress = 1;
                    }
                    else {
                        this.serviceRequest.serviceProject.isSameAddress = 0;
                    }

                    if (this.serviceRequest.serviceProject.addressSelected != null && this.serviceRequest.serviceProject.addressSelected != undefined) {
                        this.serviceRequest.serviceProject.idAddress = this.serviceRequest.serviceProject.addressSelected.id;
                    }

                    if (this.serviceRequest.addressCategorySelected != null && this.serviceRequest.addressCategorySelected != undefined) {
                        this.serviceRequest.serviceProject.idAddressCategory = this.serviceRequest.addressCategorySelected.id;
                    }

                    if (this.serviceRequest.citySelected != null && this.serviceRequest.citySelected != undefined) {
                        this.serviceRequest.serviceProject.idCity = this.serviceRequest.citySelected.id;
                    }

                    if (startImmediately == true || 
                        this.serviceRequest.idUserResponsible == null || 
                        this.serviceRequest.idUserResponsible == undefined || 
                        this.serviceRequest.idUserResponsible == 0) {
                        this.serviceRequest.idUserResponsible = this.userLoggedGetters.id;
                    }

                    if (this.serviceRequest.sendWelcomeEmail == true) {
                        this.serviceRequest.sendWelcomeEmail = 1;
                    }
                    else {
                        this.serviceRequest.sendWelcomeEmail = 0;
                    }

                    const result = await this.$store.dispatch("serviceModule/CreateUpdate", this.serviceRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);

                        const updateStatusRequest = {
                            id: result.id,
                            status: 3, //Sales - In Progress
                            startImmediately: startImmediately == true ? 1 : 0
                        };

                        if (startImmediately == true) {
                            await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);
                            
                            this.$router.push({ name: 'scopeOfWork', params: {id: result.id} });
                        }
                        else {
                            this.cancel();
                        }
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            },            

            async saveAndStartImmediately() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will save the JOB and go directly to the Scope of Work, confirm you decision?',
                    methodConfirm: this.save,
                    params: true
                };

            },

            async checkAddressProject() {

                if (this.serviceRequest.serviceProject.isSameAddress == true) {
                    if (this.newAddress == 0) {
                        this.serviceRequest.serviceProject.addressSelected = this.serviceRequest.addressSelected;
                    }
                    else {
                        this.serviceRequest.serviceProject.address = this.serviceRequest.address;
                        this.serviceRequest.serviceProject.zipCode = this.serviceRequest.zipCode;
                        this.serviceRequest.serviceProject.addressCategorySelected = this.serviceRequest.addressCategorySelected
                        this.serviceRequest.serviceProject.stateSelected = this.serviceRequest.stateSelected
                        this.serviceRequest.serviceProject.citySelected = this.serviceRequest.citySelected
                        this.serviceRequest.serviceProject.subdivision = this.serviceRequest.subdivision;
                    }
                }
            },

            checkNewAddress() {
                this.newAddress = this.isCustomer ? 0 : 1;
            },

            clearAddress() {
                this.serviceRequest.address = null;
                this.serviceRequest.zipCode = null;
                this.serviceRequest.contactName = null;
                this.serviceRequest.addressCategorySelected = null;
                this.serviceRequest.stateSelected = null;
                this.serviceRequest.citySelected = null;
                this.serviceRequest.subdivision = null;
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    tbody > tr:hover {
        background-color: transparent !important;
    }

    .selectedRow, .selectedRow:hover {
        background-color: var(--color__main) !important;
        color: #fff;
        font-weight: bold;
    }

</style>